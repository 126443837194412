import React, { useEffect, useState } from "react";
import clsx from "clsx";
import PropTypes from "prop-types";
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles,
  TableFooter,
  TablePagination,
} from "@material-ui/core";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useGet } from "../../../hooks/useGet";
import { useNavigate } from 'react-router-dom';

const useStyles = makeStyles(() => ({
  root: {},
  tableRow: {
    cursor: "pointer",
  },
}));

const Exams = ({ className, refreshData, setRefreshData, ...rest }) => {
  const classes = useStyles();
  const navigate = useNavigate();
  const [exams, setExams] = useState([]);
  const [pagination, setPagination] = useState({});
  const [limit, setLimit] = useState(5);
  const [newPage, setNewPage] = useState(1);
  const [result, get] = useGet(`/exams?page=${newPage}&limit=${limit}`);

  useEffect(() => {
    get();
  }, []);

  useEffect(() => {
    if (refreshData) {
      get();
      setRefreshData(false);
    }
  }, [limit, newPage, refreshData]);

  useEffect(() => {
    if (result.data) {
      setExams(result.data);
      setPagination({
        totalItems: result.data.length,
        currentPage: newPage
      });
    }
  }, [result]);

  console.log('Current exams state:', exams);

  const handleExamClick = (exam) => {
    navigate('/exam-questions', { state: { exam } });
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="All Exams" />
      <Divider />
      <PerfectScrollbar>
        <Box minWidth={800}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>ID</TableCell>
                <TableCell>Grade</TableCell>
                <TableCell>Label</TableCell>
                <TableCell>Name</TableCell>
                <TableCell>Subject</TableCell>
                <TableCell>Language</TableCell>
                <TableCell>Label</TableCell>
                <TableCell>Year</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {Array.isArray(exams) && exams.map((exam) => (
                <TableRow
                  className={classes.tableRow}
                  hover
                  key={exam.id}
                  onClick={() => handleExamClick(exam)}
                  style={{ cursor: 'pointer' }}
                >
                  <TableCell>{exam.id}</TableCell>
                  <TableCell>{exam.grade}</TableCell>
                  <TableCell>{exam.label}</TableCell>
                  <TableCell>{exam.name}</TableCell>
                  <TableCell>{exam.subject?.subject || ''}</TableCell>
                  <TableCell>{exam.subject?.language || ''}</TableCell>
                  <TableCell>{exam.subject?.label || ''}</TableCell>
                  <TableCell>{exam.year}</TableCell>
                </TableRow>
              ))}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[
                    5,
                    10,
                    25,
                    { label: "All", value: 10000 },
                  ]}
                  count={pagination.totalItems || 0}
                  rowsPerPage={limit}
                  page={pagination.currentPage ? pagination.currentPage - 1 : 0}
                  SelectProps={{
                    inputProps: { "aria-label": "rows per page" },
                    native: true,
                  }}
                  onChangePage={(e, newPage) => {
                    setNewPage(newPage + 1);
                    setRefreshData(true);
                  }}
                  onChangeRowsPerPage={(e) => {
                    setLimit(parseInt(e.target.value, 10));
                    setRefreshData(true);
                  }}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

Exams.propTypes = {
  className: PropTypes.string,
  refreshData: PropTypes.bool,
  setRefreshData: PropTypes.func,
};

export default Exams;