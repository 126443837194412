import React, { useState, useEffect } from "react";
import {
  Container,
  Grid,
  makeStyles,
  Modal,
  Button,
  TextField,
  Box,
} from "@material-ui/core";
import Page from "src/components/Page";
import Assets from "./Assets";
import Exams from "./Exams";
import QuestionRecommendations from '../QuestionRecommendations';
import AddTransactionModal from "./AddTransactionModal";
import VOICELIP from "../../../icons/logo-dark.svg";
import { useNavigate } from "react-router-dom";
import { getBaseUrl, getToken } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: "100%",
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3),
  },
  modalContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  subHeader: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "17px",
  },
  logo: {
    margin: "auto",
    width: "100%",
    height: "81px",
  },
}));

const orderByOptions = [
  {
    value: "",
    label: "",
  },
  {
    value: "totalTimes",
    label: "Total Views",
  },
  {
    value: "uniqueTimes",
    label: "Unique Views",
  },
];

const Dashboard = () => {
  const classes = useStyles();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [clickedBook, setClickedBook] = useState({});
  const [refreshData, setRefreshData] = useState(true);
  const [orderBy, setOrderBy] = useState('');
  const navigate = useNavigate();

  const onOpenBook = (book) => {
    setClickedBook(book);
    setIsOpenModal(true);
  };

  const onCreateBook = () => {
    setClickedBook(null);
    setIsOpenModal(true);
  };

  const onSort = (value) => {
    setOrderBy(value)
    setRefreshData(true)
  };

  useEffect(() => {
    if (!getToken() || !getBaseUrl()) {
      navigate("/login", { replace: true });
    }
  }, []);

  return (
    <Page className={classes.root} title="Dashboard">
      <Container maxWidth={false}>
        <img className={classes.logo} src={VOICELIP} alt="logo" />
        <div className={classes.subHeader}>
          <Button
            color="primary"
            variant="contained"
            onClick={() => onCreateBook()}
          >
            Create Book
          </Button>
          <TextField
              label="Sort By"
              select
              variant="outlined"
              value={orderBy}
              onChange={(e) =>
                onSort(e.target.value)
              }
              SelectProps={{
                native: true,
              }}
            >
              {orderByOptions.map((sort) => (
                <option key={sort.value} value={sort.value}>
                  {sort.label}
                </option>
              ))}
            </TextField>
        </div>
        <Assets
          onOpenBook={onOpenBook}
          refreshData={refreshData}
          setRefreshData={setRefreshData}
          orderBy={orderBy}
        />
        <Box mt={3}>
          <Exams
            refreshData={refreshData}
            setRefreshData={setRefreshData}
          />
        </Box>
        <Box mt={3}> <QuestionRecommendations /></Box>
        <Modal
          open={isOpenModal}
          className={classes.modalContainer}
          onClose={() => setIsOpenModal(false)}
        >
          <>
            <AddTransactionModal
              setIsOpenModal={setIsOpenModal}
              book={clickedBook}
              setRefreshData={setRefreshData}
            />
          </>
        </Modal>
        <Grid container>
          {/* <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <Budget />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TotalCustomers />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TasksProgress />
          </Grid>
          <Grid
            item
            lg={3}
            sm={6}
            xl={3}
            xs={12}
          >
            <TotalProfit />
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
            <Sales />
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <TrafficByDevice />
          </Grid>
          <Grid
            item
            lg={4}
            md={6}
            xl={3}
            xs={12}
          >
            <LatestProducts />
          </Grid>
          <Grid
            item
            lg={8}
            md={12}
            xl={9}
            xs={12}
          >
            <LatestOrders />
          </Grid> */}
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
