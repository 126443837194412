import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  makeStyles,
  Grid
} from '@material-ui/core';
import PropTypes from 'prop-types';
import { usePatch } from '../../hooks/usePatch';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paper': {
      maxWidth: '800px',
      width: '100%'
    }
  },
  field: {
    marginBottom: theme.spacing(2)
  }
}));

const UpdateQuestionModal = ({ open, handleClose, question }) => {
  const classes = useStyles();
  const [formData, setFormData] = useState({
    _id: '',
    number: '',
    title: '',
    type: '',
    answers: [],
    correctAnswer: [],
    additionalText: '',
    exam: '',
    subject: ''
  });

  const [, patch] = usePatch();

  // Initialize form data when question prop changes
  useEffect(() => {
    if (question) {
      const initialData = {
        _id: question.id || '',
        number: question.number || '',
        title: question.title || '',
        type: question.type || '',
        answers: question.answers || [],
        correctAnswer: question.correctAnswer || [],
        additionalText: question.additionalText || '',
        exam: question.exam || '',
        subject: question.subject || ''
      };
      setFormData(initialData);
    }
  }, [question]);

  // Handle text field changes
  const handleTextChange = (event) => {
    const { name, value } = event.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value
    }));
  };

  // Handle answers field change
  const handleAnswersChange = (event) => {
    try {
      const value = JSON.parse(event.target.value);
      setFormData((prev) => ({
        ...prev,
        answers: value
      }));
    } catch (error) {
      console.error('Error parsing answers:', error);
    }
  };

  // Handle correct answer field change
  const handleCorrectAnswerChange = (event) => {
    try {
      const value = JSON.parse(event.target.value);
      setFormData((prev) => ({
        ...prev,
        correctAnswer: value
      }));
    } catch (error) {
      console.error('Error parsing correctAnswer:', error);
    }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (question?.id) {
      try {
        const dataToSend = {
          ...formData,
          id: question.id,
          title: formData.title.replace(/\\n/g, '\n') // Unescape any already escaped newlines
        };
        await patch(`/questions/${question.id}`, dataToSend);
        toast.success('Question updated successfully!');
        handleClose();
      } catch (error) {
        toast.error('Failed to update question.');
      }
    }
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      className={classes.root}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Update Question</DialogTitle>
      <form onSubmit={handleSubmit}>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="ID"
                name="_id"
                value={formData._id}
                variant="outlined"
                className={classes.field}
                disabled
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Number"
                name="number"
                onChange={handleTextChange}
                type="number"
                value={formData.number}
                variant="outlined"
                className={classes.field}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Title"
                name="title"
                onChange={handleTextChange}
                value={formData.title.replace(/\n/g, '\\n')}
                variant="outlined"
                className={classes.field}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Type"
                name="type"
                onChange={handleTextChange}
                value={formData.type}
                variant="outlined"
                className={classes.field}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Additional Text"
                name="additionalText"
                onChange={handleTextChange}
                value={formData.additionalText}
                variant="outlined"
                multiline
                rows={4}
                className={classes.field}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Answers"
                name="answers"
                onChange={handleAnswersChange}
                value={JSON.stringify(formData.answers, null, 2)}
                variant="outlined"
                multiline
                rows={4}
                className={classes.field}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Correct Answer (JSON Array)"
                name="correctAnswer"
                onChange={handleCorrectAnswerChange}
                value={JSON.stringify(formData.correctAnswer)}
                variant="outlined"
                multiline
                rows={4}
                className={classes.field}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button type="submit" color="primary" variant="contained">
            Update Question
          </Button>
        </DialogActions>
      </form>
      <ToastContainer />
    </Dialog>
  );
};

UpdateQuestionModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  question: PropTypes.object
};

export default UpdateQuestionModal;