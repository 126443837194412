import React, { useEffect, useState } from 'react';
import {
  Box,
  Container,
  Card,
  CardHeader,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Divider,
  makeStyles,
  Tooltip
} from '@material-ui/core';
import Page from 'src/components/Page';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useLocation } from 'react-router-dom';
import UpdateQuestionModal from './UpdateQuestionModal';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useGet } from '../../hooks/useGet'; // Adjust the import based on your actual hook

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  cell: {
    maxWidth: 200,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  }
}));

const ExamQuestionsView = () => {
  const classes = useStyles();
  const location = useLocation();
  const { exam: initialExam } = location.state; // Get the initial exam from location state
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [exam, setExam] = useState(initialExam); // State to hold the current exam
  const [result, get] = useGet(`/exams?id=${exam.id}`); // Destructure as array

  useEffect(() => {
    if (result?.data) {
      setExam(result.data[0]);
    }
  }, [result]); // This will run whenever result changes

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedQuestion(null);
    get(); // Trigger the API call
  };

  const handleOpenModal = (question) => {
    setSelectedQuestion(question);
    setIsModalOpen(true);
  };

  useEffect(() => {
    // This effect runs when the exam state changes
    if (exam) {
      // You can perform any additional logic here if needed
      console.log('Exam updated:', exam);
    }
  }, [exam]); // Dependency array includes exam

  return (
    <Page className={classes.root} title="Exam Questions">
      <Container maxWidth={false}>
        <Card>
          <CardHeader title={`Questions for Exam: ${exam.name}`} />
          <Divider />
          <PerfectScrollbar>
            <Box minWidth={1400}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>ID</TableCell>
                    <TableCell>Number</TableCell>
                    <TableCell>Title</TableCell>
                    <TableCell>Type</TableCell>
                    <TableCell>Answers</TableCell>
                    <TableCell>Correct Answer</TableCell>
                    <TableCell>Additional Text</TableCell>
                    <TableCell>Exam</TableCell>
                    <TableCell>Subject</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {exam.questions.map((question) => (
                    <TableRow
                      hover
                      key={question._id}
                      onClick={() => handleOpenModal(question)}
                      style={{ cursor: 'pointer' }}
                    >
                      <TableCell>{question.id}</TableCell>
                      <TableCell>{question.number}</TableCell>
                      <TableCell className={classes.cell}>
                        <Tooltip title={question.title || ''}>
                          <pre style={{ margin: 0, fontFamily: 'inherit' }}>
                            {question.title || '-'}
                          </pre>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{question.type || '-'}</TableCell>
                      <TableCell className={classes.cell}>
                        <pre style={{ margin: 0, whiteSpace: 'pre-wrap' }}>
                          {JSON.stringify(question.answers, null, 2) || '-'}
                        </pre>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <pre style={{ margin: 0, whiteSpace: 'pre-wrap' }}>
                          {JSON.stringify(question.correctAnswer, null, 2) || '-'}
                        </pre>
                      </TableCell>
                      <TableCell className={classes.cell}>
                        <Tooltip title={question.additionalText || ''}>
                          <span>{question.additionalText || '-'}</span>
                        </Tooltip>
                      </TableCell>
                      <TableCell>{question.exam || '-'}</TableCell>
                      <TableCell>{question.subject || '-'}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
        </Card>
        <ToastContainer />
        <UpdateQuestionModal
          open={isModalOpen}
          handleClose={handleCloseModal}
          question={selectedQuestion}
        />
      </Container>
    </Page>
  );
};

export default ExamQuestionsView;