import React, { useState, useEffect } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, CircularProgress, TableFooter, TablePagination, CardHeader, Divider, Switch } from '@material-ui/core';
import { useGet } from '../../hooks/useGet';
import { usePatch } from '../../hooks/usePatch';
import UpdateQuestionModal from '../reports/UpdateQuestionModal';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function QuestionRecommendations() {
  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(5);
  const [result, get] = useGet(`/question-recommendations?page=${page + 1}&limit=${limit}`);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [questionId, setQuestionId] = useState(null);
  // const [questionResult, getQuestion] = useGet(questionId ? `/questions?id=${questionId}` : '');
  const [questionResult, getQuestion] = useGet(questionId ? `/questions/${questionId}` : '');
  const [, patch] = usePatch();
  const [statusMap, setStatusMap] = useState({});
  const [answers, setAnswers] = useState('');
  const [correctAnswers, setCorrectAnswers] = useState('');

  useEffect(() => {
    get();
  }, [get, page, limit]);

  useEffect(() => {
    if (questionId) {
      getQuestion();
    }
  }, [questionId, getQuestion]);

  useEffect(() => {
    if (result && result.data && Array.isArray(result.data)) {
      const initialStatusMap = {};
      result.data.forEach((rec) => {
        initialStatusMap[rec.id] = rec.status;
      });
      setStatusMap(initialStatusMap);
    }
  }, [result]);

  const handlePageChange = (e, newPage) => {
    setPage(newPage);
  };

  const handleLimitChange = (e) => {
    const newLimit = e.target.value === 'All' ? 10000 : parseInt(e.target.value, 10);
    setLimit(newLimit);
    setPage(0);
  };

  const handleRowClick = (rec) => {
    if (rec.question?.id) {
      setQuestionId(rec.question.id);
    }
  };

  useEffect(() => {
    if (questionResult.data) {
      setSelectedQuestion(questionResult.data);
      setIsModalOpen(true);
    }
  }, [questionResult.data]);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedQuestion(null);
    setQuestionId(null);
  };

  const handleStatusToggle = async (rec) => {
    try {
      const newStatus = statusMap[rec.id] === "unresolved" ? "resolved" : "unresolved";
      await patch(`/question-recommendations/${rec.id}`, { status: newStatus });
      setStatusMap((prevStatusMap) => ({
        ...prevStatusMap,
        [rec.id]: newStatus,
      }));
      toast.success('Question status updated successfully!');
    } catch (error) {
      toast.error('Failed to update question status.');
      console.error('Error updating question status:', error);
    }
  };

  const getStatusStyle = (status) => {
    if (status === 'resolved') {
      return { backgroundColor: '#d4edda' }; // Pale green for resolved
    } else if (status === 'unresolved') {
      return { backgroundColor: '#f8d7da' }; // Pale red for unresolved
    }
    return {}; // Default style
  };

  if (result.loading) return <CircularProgress />;
  if (result.error) return <div>Error loading recommendations: {result.error.message}</div>;

  return (
    <div>
      {/* <CardHeader title="Question Recommendations" />÷÷÷÷÷÷÷÷÷÷÷÷÷÷÷ */}
      <Divider />
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Question ID</TableCell>
              <TableCell>Recommendation</TableCell>
              <TableCell>Question Tile</TableCell>
              <TableCell>Status</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {result.data && result.data.map((rec) => (
              <TableRow
                key={rec.id}
                onClick={(e) => {
                  if (!e.target.closest('.status-switch')) {
                    handleRowClick(rec);
                  }
                }}
                style={{ cursor: 'pointer' }}
              >
                <TableCell>{rec.id}</TableCell>
                <TableCell>{rec.question?.id}</TableCell>
                <TableCell>{rec.recommendation}</TableCell>
                <TableCell>{rec.question?.title}</TableCell>
                <TableCell style={getStatusStyle(statusMap[rec.id])}>
                  <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                    <Switch
                      className="status-switch"
                      checked={statusMap[rec.id] === 'resolved'}
                      onChange={() => handleStatusToggle(rec)}
                      color="primary"
                      size="small"
                    />
                    {statusMap[rec.id] === 'unresolved' ? 'unresolved' : 'resolved'}
                  </div>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                rowsPerPageOptions={[
                  5,
                  10,
                  25,
                  { label: "All", value: 10000 },
                ]}
                count={result.data ? result.data.length : 0}
                rowsPerPage={limit}
                page={page}
                SelectProps={{
                  inputProps: { "aria-label": "rows per page" },
                  native: true,
                }}
                onChangePage={handlePageChange}
                onChangeRowsPerPage={handleLimitChange}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <ToastContainer />

      {selectedQuestion && (
        <UpdateQuestionModal
          open={isModalOpen}
          handleClose={handleCloseModal}
          question={selectedQuestion}
        />
      )}
    </div>
  );
}

export default QuestionRecommendations;
