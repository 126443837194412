import { useState, useCallback } from "react";
import axios from "axios";
import { getBaseUrl, getToken } from "../utils";

export const usePatch = () => {
  const [result, setResult] = useState({
    data: null,
    error: null
  });
  const BASE_URL = getBaseUrl();
  const header = {
    "Content-Type": "application/json",
    Authorization: `Bearer ${getToken()}`,
  };

  const patch = useCallback((url, data) => {
    setResult((prevState) => ({ ...prevState }));

    const cleanData = JSON.parse(JSON.stringify(data));

    return axios
      .patch(`${BASE_URL}${url}`, cleanData, { headers: header })
      .then((result) => {
        setResult({ data: result.data });
      })
      .catch((error) => {
        setResult({ data: null, error });
      });
  }, []);

  return [result, patch];
};
